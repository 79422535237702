import React, { useState, useEffect, useImperativeHandle, forwardRef, ReactNode } from 'react';
import { css } from '@emotion/react';
import tw from 'twin.macro';

import Text from '@src/components/ui/Text';
import closeIconLookup from '@src/images/internal/closeicon.svg';
import ModalStyle from './style';

export interface ModalProps {
  id?: string;
  isOpen?: boolean;
  children?: any;
  className?: any;
  onClose?: any;
  ref?: any;
  noBackdrop?: boolean;
  titleModal?: string | ReactNode;
  subTitleModal?: string | ReactNode;
  isModalTab?: boolean;
  style?: any;
  headerContent?: ReactNode;
  isShowBackgroundLogo?: boolean;
}

let modalResult = false;

function onEscKeyDown(e: any, setState: any): void {
  if (e.key !== 'Escape') return;
  setState({ fadeType: 'out' });
}

function closeModal(e: any, setState: any): void {
  e.preventDefault();
  setState({ fadeType: 'out' });
}

function transitionEnd(props: ModalProps, state: any): void {
  if (state.fadeType === 'in') {
    modalResult = false;
    props.noBackdrop && document.body.classList.add('overflow-hidden');
    return;
  }

  if (state.fadeType === 'out') {
    props.noBackdrop && document.body.classList.remove('overflow-hidden');
    props.onClose && props.onClose(modalResult);
  }
}

const Modal: React.FC<ModalProps> = forwardRef(
  (
    {
      children,
      id,
      className,
      noBackdrop,
      headerContent,
      titleModal,
      subTitleModal,
      isModalTab = false,
      style = {},
      ...props
    },
    ref
  ) => {
    const [state, setState] = useState({ fadeType: '' });
    const onEscKeyDownHandler = (e: any) => onEscKeyDown(e, setState);
    id = id || `modal-${new Date().getTime()}`;

    useEffect(() => {
      window.addEventListener('keydown', onEscKeyDownHandler, false);

      if (props.isOpen) {
        setState({ fadeType: 'in' });
      } else {
        setState({ fadeType: 'out' });
      }

      return () => {
        window.removeEventListener('keydown', onEscKeyDownHandler, false);
      };
    }, [props.isOpen]);

    useImperativeHandle(ref, () => ({
      close: (result?: boolean) => {
        modalResult = result || false;
        setState({ fadeType: 'out' });
        document.body.classList.remove('overflow-hidden');
      },
    }));

    return (
      <ModalStyle
        id={id}
        className={`wrapper fade-${state.fadeType} ${className || ''} ${
          isModalTab ? 'tab-modal' : ''
        }`}
        role="dialog"
        onTransitionEnd={(e: any) => {
          // only trigger for opacity transition
          if (e.propertyName === 'opacity') {
            transitionEnd(props, state);
          }
        }}
        style={{ ...style }}
      >
        <div className="box-dialog">
          <div className="header flex items-start justify-between pr-5">
            {headerContent && <>{headerContent}</>}
            {titleModal && (
              <Text.subtitle
                css={css`
                  ${tw`font-avenirHeavy mr-5 w-full leading-[28.5px]`}
                `}
              >
                {titleModal}
              </Text.subtitle>
            )}
            <div
              onClick={(e) => closeModal(e, setState)}
              className="cursor-pointer close z-100 ml-auto"
            >
              <img src={closeIconLookup} />
            </div>
          </div>

          {subTitleModal && <Text.body className="mb-5 mt-2 pr-5">{subTitleModal}</Text.body>}

          <div className="box-content-wrapper mt-5">
            <div className="box-content">{children}</div>
          </div>
        </div>
        {!noBackdrop && (
          <div
            className="background"
            onMouseDown={(e) => {
              closeModal(e, setState);
            }}
          />
        )}
      </ModalStyle>
    );
  }
);

export default Modal;

export * from './ModalV2';
