import config from '@src/config';
import { navigate } from 'gatsby';

const navigateTo = {
  dashboard: (opts: any = {}, reload?: boolean, organizationUuid?: string) => {
    if (!reload) {
      navigate(`/${organizationUuid ? organizationUuid : ''}`, opts);
      return;
    }
    const location = typeof window !== 'undefined' ? window.location : null;
    location && location.replace(`/${organizationUuid}`);
  },
  login: (opts?: any) => {
    navigate('/login', opts);
  },
  logout: () => {
    navigate('/logout');
  },
  notFound: () => {
    navigate('/404');
  },
  forbidden: () => {
    navigate('/403');
  },
  page: (path: string | null) => {
    const p = path || '';
    const location = typeof window !== 'undefined' ? window.location : null;
    location && location.replace(`/${p}`);
  },
  organizationSetting: (organizationUuid: string) => {
    navigate(`/${organizationUuid}/team/domain-settings/`);
  },
  handbookSetting: (organizationUuid: string, handbookId: number) => {
    navigate(`/${organizationUuid}/handbook/${handbookId}/settings`);
  },
  integration: (organizationUuid: string) => {
    navigate(`/${organizationUuid}/integrations/`);
  },
  manageEmployees: (organizationUuid: string) => {
    navigate(`/${organizationUuid}/team/`);
  },
  masterPolicies: () => {
    navigate('/internal/master-policies');
  },
  lookUps: (organizationId?: number) => {
    let eid = '';
    if (organizationId) {
      eid = btoa(organizationId.toString());
    }
    navigate(`/internal/lookups${eid && `?eid=${eid}`}`);
  },
  createHandbook: (organizationUuid: string, folderId?: number) => {
    const url = folderId
      ? `/${organizationUuid}/create?folderId=${folderId}`
      : `/${organizationUuid}/create`;
    navigate(url);
  },
  // External Navigation
  editHandbook: (path: string) => {
    navigate(config.EDITOR_DOMAIN + path);
  },
  employeeHandbook: (path: string | null) => {
    let url = config.HANDBOOK_VIEWER_DOMAIN;
    if (path) {
      url += path;
    }

    navigate(url);
  },
};

export default navigateTo;
