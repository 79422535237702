import { IoMdTime } from 'react-icons/io';

import { LoadingDots } from './styles';
import CircularProgressbar from './CircularProgressbar';
import { ProgressResponse } from '.';

interface Props {
  token: string;
  data: ProgressResponse;
  preparingTitle: string;
  error?: string;
}

export function PreparingDownloadLayout(props: Props) {
  const { data, error, preparingTitle } = props;

  return (
    <>
      {!error && (
        <>
          <div className="text-coral-1">
            Please keep this window open until all downloads are completed!
          </div>
          <div className="flex items-center justify-between mt-4 text-airmason-dark-grey">
            <div className="flex items-center">
              <IoMdTime size={25} className="mr-4" />
              <div>{preparingTitle}</div>
              <LoadingDots>
                <div className="dot-pulse" />
              </LoadingDots>
            </div>
            <CircularProgressbar percent={data.progress} />
          </div>
        </>
      )}
      <div>
        {error && <div className="text-coral-1">{error}</div>}
        {data.error && <div className="text-coral-1">{data.error}</div>}
        {data.message && <div className="text-green-4">{data.message}</div>}
      </div>
    </>
  );
}
