import gql from 'graphql-tag';
import { USER_ACCOUNT_TYPE_FRAGMENT } from './useUserAccountType';

export const USER_HANDBOOK_ACCESS_RIGHTS_FRAGMENT = gql`
  fragment UserHandbookAccessFragment on UserHandbookAccess {
    handbookId
    userId
    canEditHandbook
    canPublishHandbook
    canViewSignatures
    canEditSignatureSettings
    canEditReminderSettings
    canEditCollaboratorAccess
    canViewHandbook
    canEditEmployeeAccess
    canDeleteHandbook
    canEditHandbookSettings
    canAccessHandbook
  }
`;

export const USER_FOLDER_ACCESS_RIGHTS_FRAGMENT = gql`
  fragment UserFolderAccessFragment on UserFolderAccess {
    id
    folderId
    userId
    canViewFolder
    canCreateHandbook
    canEditCollaboratorAccess
    canMoveHandbook
    canEditFolderSettings
    isFolderAdmin
    isFolderPublisher
    isFolderEditor
    canDuplicateHandbook
  }
`;

export const HANDBOOK_CONFIGURATION_FRAGMENT = gql`
  fragment HandbookConfigurationFragment on HandbookConfiguration {
    id
    coverImage
    coverColor
  }
`;

export const COLLABORATOR_FRAGMENT = gql`
  fragment CollaboratorsFragment on User {
    id
    name
    firstName
    lastName
    email
    isRootUser
    organizationUserRelations {
      industryType
      teamName
      location
      createdAt
      activatedDate
      isVerified
      invitedDate
      userAccountType {
        ...UserAccountTypeFragment
      }
    }
    userHandbookAccesss {
      ...UserHandbookAccessFragment
      handbook {
        id
        name
        folderId
      }
    }
    userFolderAccesses {
      ...UserFolderAccessFragment
      folder {
        id
        name
      }
    }
  }
  ${USER_ACCOUNT_TYPE_FRAGMENT}
  ${USER_HANDBOOK_ACCESS_RIGHTS_FRAGMENT}
  ${USER_FOLDER_ACCESS_RIGHTS_FRAGMENT}
`;

export const PROVIDER_FRAGMENT = gql`
  fragment ProviderFragment on AuthenticationProviderConfiguration {
    id
    provider
    issuer
    entryPoint
    cert
    type
    isEnabled
  }
`;

export const HANDBOOK_DASHBOARD_FRAGMENT = gql`
  fragment HandbookDashboardFragment on Handbook {
    id
    name
    isPublished
    slug
    requireSignatures
    updatedAt
    publishedDate
    status
    createdAt
    policyUpdatesCount
    handbookConfiguration {
      ...HandbookConfigurationFragment
    }
    handbookVersions {
      id
      version
      createdAt
    }
    subProjectChecklist {
      id
      status
      projectChecklist {
        id
      }
      tasks {
        id
        done
      }
    }
  }
  ${HANDBOOK_CONFIGURATION_FRAGMENT}
`;

export const CONNECTED_HR_INTEGRATIONS_FRAGMENT = gql`
  fragment ConnectedIntegrationsFragment on OrganizationHRIntegration {
    id
    hrSystem
    apiKey
    signatureModuleId
    subDomain
    extraModuleId
    slug
    companyId
    companyIds
    autoActivate
    isPatch
    isEnabled
    credentials {
      username
      password
      hostName
      headers {
        usCustomerApiKey
      }
      inventoryProfileName
    }
    ripplingConfig {
      connected
    }
    isSSOEnabled
    location
  }
`;
